import classNames from 'classnames';
import Flicking from '@egjs/react-flicking';
import React, { useEffect, useState } from 'react';

import type { RefObject } from 'react';
import type { ChangedEvent, WillChangeEvent } from '@egjs/react-flicking';

import { ALIGN, EVENTS } from '~/components/Slider/Slider.constants';

import styles from '~/components/Slider/SliderCounterOverlay.module.scss';

export const SliderCounterOverlay = ({
	align = ALIGN.RIGHT,
	defaultIndex = 0,
	flickingRef,
	panelCount,
}: {
	align: keyof typeof ALIGN,
	defaultIndex?: number,
	flickingRef: RefObject<Flicking>,
	panelCount: number,
}) => {
	const [currentIndex, setCurrentIndex] = useState(defaultIndex);

	const [isVisible, setIsVisible] = useState(true);

	function handleWillChange({ index }: WillChangeEvent) {
		setCurrentIndex(index);

		setIsVisible(true);
	}

	function handleChanged({ index }: ChangedEvent) {
		setCurrentIndex(index);

		setIsVisible(false);
	}

	useEffect(() => {
		flickingRef.current?.on?.(EVENTS.CHANGED, handleChanged);

		flickingRef.current?.on?.(EVENTS.WILL_CHANGE, handleWillChange);

		return () => {
			flickingRef.current?.off?.(EVENTS.CHANGED, handleChanged);

			flickingRef.current?.off?.(EVENTS.WILL_CHANGE, handleWillChange);
		};
	}, [flickingRef.current]);

	return (
		<div
			className={
				classNames(styles.sliderCounterOverlay, {
					[styles.sliderCounterOverlayVisible]: isVisible,
					'tw-left-[10px]': align === ALIGN.LEFT,
					'tw-right-[10px]': align === ALIGN.RIGHT,
				})
			}
			data-qa="slider-counter"
		>
			{currentIndex + 1}/{panelCount}<span className="tw-sr-only"> items</span>
		</div>
	);
};
