import Flicking from '@egjs/react-flicking';
import React, { useEffect, useState } from 'react';

import type { RefObject } from 'react';
import type { WillChangeEvent } from '@egjs/react-flicking';

import { EVENTS } from '~/components/Slider/Slider.constants';

import styles from '~/components/Gallery/GalleryCounter.module.scss';

export const GalleryCounter = ({
	defaultIndex = 0,
	flickingRef,
	panelCount,
}: {
	defaultIndex?: number,
	flickingRef: RefObject<Flicking>,
	panelCount: number,
}) => {
	const [currentIndex, setCurrentIndex] = useState(defaultIndex);

	function handleWillChange({ index }: WillChangeEvent) {
		setCurrentIndex(index);
	}

	useEffect(() => {
		flickingRef.current?.on?.(EVENTS.WILL_CHANGE, handleWillChange);

		return () => {
			flickingRef.current?.off?.(EVENTS.WILL_CHANGE, handleWillChange);
		};
	}, [flickingRef.current]);

	return (
		<div
			className={styles.galleryCounter}
			data-qa="gallery-counter"
		>
			{currentIndex + 1} of {panelCount}<span className="tw-sr-only"> items</span>
		</div>
	);
};
