import React, { forwardRef } from 'react';

import type { ForwardedRef } from 'react';

import { ButtonBase } from '~/components/Buttons/Components/ButtonBase';
import { s7ContentPath } from '~/global/global.constants';

import styles from '~/components/Gallery/GalleryFullscreen.module.scss';

export const GalleryFullscreen = forwardRef(({ onClick }: { onClick?: () => void }, ref: ForwardedRef<HTMLButtonElement>) => {
	return (
		<ButtonBase
			className={styles.galleryFullscreen}
			data-qa="gallery-fullscreen"
			onClick={onClick}
			ref={ref}
		>
			<img
				alt=""
				className={styles.galleryFullscreenImage}
				src={`${s7ContentPath}/enlarge`}
			/>
			<span className="tw-sr-only">
				View fullscreen media
			</span>
		</ButtonBase>
	);
});
