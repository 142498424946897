import Flicking from '@egjs/react-flicking';
import classNames from 'classnames';
import React, { useState } from 'react';

import type { AfterResizeEvent, FlickingProps } from '@egjs/react-flicking';
import type { SyncOptions } from '@egjs/flicking-plugins';
import type { RefObject } from 'react';

import { Slider } from '~/components/Slider/Slider';
import { maxBy } from '~/util/maxBy';

import type { GallerySliderPanelData } from '~/components/Gallery/Gallery.types';

import styles from '~/components/Gallery/Gallery.module.scss';

export const GallerySlider = ({
	defaultSliderHeight,
	flickingRef,
	plugins,
	showSliderArrowsIndented,
	showSliderPanelChildren,
	sliderPanels,
	sliderProps,
	sliderProps: {
		defaultIndex = 0,
	} = {},
	sliderPropsByBreakpoints,
}: {
	defaultSliderHeight: number,
	flickingRef: RefObject<Flicking>,
	plugins: Partial<SyncOptions>[],
	showSliderArrowsIndented?: boolean,
	showSliderPanelChildren?: boolean,
	sliderPanels: GallerySliderPanelData[],
	sliderProps?: Partial<FlickingProps>,
	sliderPropsByBreakpoints?: {
		breakpointMax: number,
		breakpointMin: number,
		sliderProps: Partial<FlickingProps>,
	}[],
}) => {
	const [sliderHeight, setSliderHeight] = useState(defaultSliderHeight);

	const panelCount = sliderPanels.length;

	const hasMultiplePanels = panelCount > 1;

	// maxWidthSliderPanel determines the overall height of the slider
	const maxWidthSliderPanel = maxBy(sliderPanels, (({ width = 0 }) => width));

	if (!panelCount) {
		return null;
	}

	function handleAfterResize({
		currentTarget: {
			panels,
		},
		sizeChanged,
	}: AfterResizeEvent) {
		const maxWidthPanel = panels[sliderPanels.indexOf(maxWidthSliderPanel)];

		const { height = 0 } = maxWidthPanel || {};

		if (sizeChanged) {
			setSliderHeight(height);
		}
	}

	return (
		<Slider
			{...sliderProps}
			className={
				classNames(styles.gallerySlider, {
					[styles.gallerySliderArrowsIndented]: showSliderArrowsIndented && hasMultiplePanels,
				})
			}
			onAfterResize={handleAfterResize}
			plugins={plugins}
			ref={flickingRef}
			sliderPropsByBreakpoints={sliderPropsByBreakpoints}
		>
			{sliderPanels.map((sliderPanel: GallerySliderPanelData, index) => {
				const {
					SliderPanelComponent,
					sliderPanelComponentProps,
				} = sliderPanel;

				return (
					<SliderPanelComponent
						defaultIndex={defaultIndex}
						defaultSliderHeight={defaultSliderHeight}
						flickingRef={flickingRef}
						index={index}
						key={`slider-panel-${index}`}
						panelCount={panelCount}
						showSliderPanelChildren={showSliderPanelChildren}
						sliderHeight={sliderHeight}
						sliderPanelComponentProps={sliderPanelComponentProps}
						useInlineStyle={sliderPanel !== maxWidthSliderPanel}
					/>
				);
			})}
		</Slider>
	);
};
